<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/Company_title.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div id="subContents">
                        <img src="@/assets/images/Info/Company_Map.png" />
                    </div>
                    <LeftMenu :menuType="'companyInfo'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue' 

export default {
    name: 'CompanyMap',
    components: {
        LeftMenu, 
    }
}
</script>
    