<template>
    <div  id="LeftMenu">
        <!-- 회사소개  -->
        <ul v-if="menuType === 'companyInfo'" style="margin-top:10px;" id="subLeft">
            <!-- <li @click="activeMenu = 'Company_menu_01'"> -->
            <li @click="setActiveMenu('Company_menu_01')">
                <div>
                <router-link to="/Company/CompanyInfo">
                    <!-- <img alt="회사 소개" src="@/assets/images/LeftTop/Company_menu_01.png" /> -->
                    <!-- <img :src="getMenuImagePath('companyInfo', 'Company_menu_01', 'LeftTop')" alt="회사 소개" /> -->
                    <img :src="getMenuImagePath('Company_menu_01', 'Company_menu_01', 'LeftTop')" alt="회사 소개" />

                </router-link>
            </div>
            </li>
            <!-- <li @click="activeMenu = 'Company_menu_02'"> -->
            <li @click="setActiveMenu('Company_menu_02')">
                <div>
                <router-link to="/Company/CompanyPerform">
                    <!-- <img alt="주요 실적" src="@/assets/images/LeftTop/Company_menu_02.png" /> -->
                    <img :src="getMenuImagePath('Company_menu_02', 'Company_menu_02', 'LeftTop')" alt="주요 실적" />
                </router-link>
            </div>
            </li>
            <!-- <li @click="activeMenu = 'Company_menu_03'"> -->
            <li @click="setActiveMenu('Company_menu_03')">
                <div>
                <router-link to="/Company/CompanyOrgani">
                    <!-- <img alt="조직도" src="@/assets/images/LeftTop/Company_menu_03.png" /> -->
                    <img :src="getMenuImagePath('Company_menu_03', 'Company_menu_03', 'LeftTop')" alt="조직도" />
                </router-link>
            </div>
            </li>
            <!-- <li @click="activeMenu = 'Company_menu_04'"> -->
            <li @click="setActiveMenu('Company_menu_04')">
                <div>
                <router-link to="/Company/CompanyMap">
                    <!-- <img alt="오시는 길" src="@/assets/images/LeftTop/Company_menu_04.png" /> -->
                    <img :src="getMenuImagePath('Company_menu_04', 'Company_menu_04', 'LeftTop')" alt="오시는 길" />
                </router-link>
            </div>
            </li>
        </ul>

        <!-- 렌탈사업부 메뉴 -->
        <ul v-else-if="menuType === 'rentalBusiness'" style="margin-top:10px;" id="subLeft">
            <li>
                <img src="@/assets/images/Product/TopLine.png" />
            </li>
            <li class="menu" @click="selectCategory(1)">        
                <img :src="getMenuImagePath(1, 'Menu01', 'Product')" alt="영상장비" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
            <li class="menu" @click="selectCategory(2)">
                <img :src="getMenuImagePath(2, 'Menu06', 'Product')" alt="음향장비" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
            <li class="menu" @click="selectCategory(3)">
                <img :src="getMenuImagePath(3, 'Menu02', 'Product')" alt="사무기기" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
            <li class="menu" @click="selectCategory(4)">
                <img :src="getMenuImagePath(4, 'Menu03', 'Product')" alt="이벤트 용품" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
            <li class="menu" @click="selectCategory(5)">
                <img :src="getMenuImagePath(5, 'Menu04', 'Product')" alt="IT 장비" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
            <li class="menu" @click="selectCategory(6)">
                <img :src="getMenuImagePath(6, 'Menu05', 'Product')" alt="텐트" />
                <img src="@/assets/images/Product/DotLine.png" />
            </li>
        </ul>


        <!-- SI사업부 메뉴 -->
        <ul v-else-if="menuType === 'siBusiness'" style="margin-top:10px;" id="subLeft">
            <ul style="margin-top:10px;" id="subLeft">
                <li>
                    <router-link to="/Operation/OperationNetwork">
                        <img alt="NETWORK 시스템" src="@/assets/images/Operation/Operation_btn_01.png" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/Operation/OperationWebCasting">
                        <img alt="WEB CASTING SYSTEM" src="@/assets/images/Operation/Operation_btn_02.png" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/Operation/OperationSIBiz">
                        <img alt="VIDEO CONFERENCE" src="@/assets/images/Operation/Operation_btn_03.png" />
                    </router-link>
                </li>
            </ul>
        </ul>

        
        <!-- 영상사업부 메뉴 -->
        <ul v-else-if="menuType === 'media'" style="margin-top:10px;" id="subLeft">
            <ul style="margin-top:10px;" id="subLeft">
                <li>
                    <router-link to="/Media/MediaAv">
                        <img alt="영상시스템" src="@/assets/images/Media/Media_btn_01.png" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/Media/MediaLed">
                        <img alt="LED SYSTEM" src="@/assets/images/Media/Media_btn_02.png" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/Media/MediaBeam">
                        <img alt="BEAM PROJECT SYSTEM" src="@/assets/images/Media/Media_btn_03.png" />
                    </router-link>
                </li>
            </ul>
        </ul>


        <!-- 고객센터 메뉴 -->
        <ul v-else-if="menuType === 'supportCenter'" style="margin-top:10px;" id="subLeft">
            <ul style="margin-top:10px;" id="subLeft">
                <li>
                    <router-link to="/SupportCenter/SupportCenterNotice">
                        <img alt="공지사항" src="@/assets/images/Service/Menu03.png" />
                    </router-link>
                </li>
            </ul>
        </ul>

    </div>
</template>

<script>
    export default {
        props: {
            menuType: {
                type: String,
                required: true
            },
            selectedCategory: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                // activeMenu: 'videoEquipment'  // 초기값 영상
                activeMenu: null 
            }
        },
        methods: {
            selectCategory(category) {
                this.clearSearch(); 
                this.activeMenu = category;
                // console.log("Category selected:", category);
                this.$emit('update:selectedCategory', category);
            },
            getMenuImagePath(category, baseImageName, menuName) {
                // console.log("Active Menu:", this.activeMenu);

                if (this.activeMenu === category) {
                    return require(`@/assets/images/${menuName}/${baseImageName}_on.png`);
                }
                return require(`@/assets/images/${menuName}/${baseImageName}.png`);
            },
            
            setInitialActiveMenu() {
                if (this.menuType === 'companyInfo') {
                    // console.log("menuType:", this.menuType);
                    this.activeMenu = 'Company_menu_01'; 
                    // console.log("Active Menu:", this.activeMenu);
                } else if (this.menuType === 'rentalBusiness') {
                    this.activeMenu = 1;
                } else {
                    this.activeMenu = null;
                }
            },
            setActiveMenu(menu) {
                this.$nextTick(() => {
                    this.activeMenu = menu;
                });
            },
            clearSearch() {
                if (this.$route.query.search) {
                    this.$router.replace({ query: { ...this.$route.query, search: undefined } });
                }
            }
        },
        watch: {
            menuType() {
                // this.setInitialActiveMenu();
            }

            
        },
        mounted() {
            this.setInitialActiveMenu();
        }, 
        beforeRouteUpdate(to, from, next) {
        switch (to.path) {
            case "/Company/CompanyInfo":
                this.setActiveMenu('Company_menu_01');
                break;
            case "/Company/CompanyPerform":
                this.setActiveMenu('Company_menu_02');
                break;
            case "/Company/CompanyOrgani":
                this.setActiveMenu('Company_menu_03');
                break;
            case "/Company/CompanyMap":
                this.setActiveMenu('Company_menu_04');
                break;
        }
        next();
    }
    }
</script>

<style scoped>
    .menu img {
        cursor: pointer;
    }
</style>
