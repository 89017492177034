<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/Company_title.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div id="subContents">
                        <img src="@/assets/images/Info/Company_Perform_01.png" />
                        <ul>
                            <li><img src="@/assets/images/Info/Company_Perform_02.png" /></li>
                            <li><img src="@/assets/images/Info/Company_Perform_03.png" /></li>
                            <li><img src="@/assets/images/Info/Company_Perform_04.png" /></li>
                            <li><img src="@/assets/images/Info/Company_Perform_05.png" /></li>
                        </ul>
                        <div style="clear:both;">
                            <img src="@/assets/images/Info/Company_Perform_06.png" />
                        </div>
                    </div>
                    <LeftMenu :menuType="'companyInfo'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue' 

export default {
    name: 'CompanyPerform',
    components: {
        LeftMenu, 
    }
}
</script>
    