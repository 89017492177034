<template>
    <div class="partner">
        <ul>
            <li><img src="@/assets/images/Main/PartnerBar.png" /></li>
            <li></li>
            <li><img src="@/assets/images/Main/PartnerBottom.png" /></li>
        </ul>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>