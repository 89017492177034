<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/Operation_Title.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div id="subContents">
                        <img src="@/assets/images/Operation/NETWORK_02.png" />
                        <img src="@/assets/images/Operation/NETWORK_01.png" />
                    </div>
                    <LeftMenu :menuType="'siBusiness'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue' 

export default {
    name: 'OperationNetwork',
    components: {
        LeftMenu, 
    }
}
</script>