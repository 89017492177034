<template>
    <div id="TabInfo">
        <ul>
            <li>
                <router-link to="/Operation/OperationWebCasting">
                    <img alt="렌탈사업부" src="@/assets/images/Main/SubTab_01.png" />
                </router-link>
            </li>
            <li>
                <router-link to="/Media/MediaAv">
                    <img alt="영상사업부" src="@/assets/images/Main/SubTab_03.png" />
                </router-link>
            </li>
            <li>
                <router-link to="/Operation/OperationSIBiz">
                    <img alt="SI사업부" src="@/assets/images/Main/SubTab_04.png" />
                </router-link>
            </li>
        </ul>
        <ul class="searchBar">
            <li>
                <input type="text" name="searchText" v-model="searchText" />
            </li>
            <li>
                <button type="button" id="btnSearch" @click="searchProducts"></button>
            </li>
        </ul>
        <ul>
            <li><img src="@/assets/images/Main/GalleryTitleBar.png" /></li>
        </ul>    
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchText: ''
            };
        },
        methods: {
            searchProducts() {
                this.$router.push({
                    name: 'ProductList', 
                    query: { search: this.searchText }
                });
            }
        }
    }
</script>