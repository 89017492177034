import { createApp } from 'vue'
import App from './App.vue'
import store from './store'; 
import router from './router'
import '@/assets/css/ChoiceRental.css'

import TopArea from '@/components/TopArea.vue';
import TopMenu from '@/components/TopMenu.vue';
import LeftMenu from '@/components/LeftMenu.vue';
import BottomArea from '@/components/BottomArea.vue';

const app = createApp(App); // 앱 인스턴스 생성

app.component('TopArea', TopArea);
app.component('TopMenu', TopMenu);
app.component('LeftMenu', LeftMenu);
app.component('BottomArea', BottomArea);

app.use(router); // 라우터를 앱에 추가
app.use(store); // 스토어도 추가

app.mount('#app'); // 앱 마운트
