import { createStore } from 'vuex';

export default createStore({
    state: {
        activeMenu: ''
    },
    mutations: {
        setActiveMenu(state, payload) {
            state.activeMenu = payload;
        }
    },
    actions: {},
    getters: {}
});
