<template>
    <div id="BottomArea">
        <div class="topLine">
            <img src="@/assets/images/Main/BottomMenu.png" />
        </div>
        <div class="menuLine">
            <img src="@/assets/images/Main/BottomContact.png" />
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>
