<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/service_title.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div id="subContents">
                        <div>
                            <img src="@/assets/images/Service/ChoiceRental_Sub_06_05.png" />
                        </div>
                        
                        <table v-if="notice" class="Noticelist" style="width:700px;color:#5B9EC2">
                            <colgroup>
                                <col width="400" />
                                <col width="60" />
                                <col width="*" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="txl" style="border-top:2px solid #808080;">
                                        {{ notice.title }}
                                    </th>
                                    <th style="border-top:2px solid #808080;">날짜 : </th>
                                    <th style="border-top:2px solid #808080;text-align:left;">
                                        {{ new Date(notice.createdDate).toLocaleString() }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="txl" style="height:250px;vertical-align:top;padding-top:30px;">
                                        <!-- {{ notice.content }}     -->
                                        <div v-html="notice.content"></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td class="txr" style="padding:20px 20px 20px;" colspan="3">
                                        <router-link to="/SupportCenter/SupportCenterNotice">목록으로</router-link>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <div v-else>
                            데이터를 불러오는 중입니다...
                        </div>
                        <!-- ... 기존의 코드 ... -->
                    </div>
                    
                    <LeftMenu :menuType="'supportCenter'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LeftMenu from '@/components/LeftMenu.vue';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
    name: 'SupportCenterNoticeView',
    components: {
        LeftMenu
    },
    data() {
        return {
            notice: null,
            error: null,  // 에러를 저장할 변수
        };
    },
    methods: {
        async fetchNotice() {
            const id = this.$route.params.id;
            try {
                const response = await axios.get(apiUrl+`/api/Customer/V1/Notices/${id}`);
                this.notice = response.data;
            } catch (error) {
                console.error('Failed to fetch notice:', error);
                this.error = "데이터를 불러오는데 실패했습니다.";  // 에러 메시지 설정
            }
        },
    },
    mounted() {
        this.fetchNotice();
    }
}
</script>

<style scoped>
    .txl {
        text-align: left;
    }
</style>