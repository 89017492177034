<template>
    <div>
        <MainContents/>
        <TabInfo/>
        <ImageGallery/>
        <BusinessPartner/>
    </div>
</template>

<script>

    import MainContents from '@/views/Main/MainContents.vue'
    import TabInfo from '@/views/Main/TabInfo.vue'
    import ImageGallery from '@/views/Main/ImageGallery.vue'
    import BusinessPartner from '@/views/Main/BusinessPartner.vue'


    export default {
        name: 'IndexView',
        components: {
        // TopArea,
        // TopMenu,
        MainContents,
        TabInfo,
        ImageGallery,
        BusinessPartner,
        // BottomArea
        }
    }
</script>
