<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/service_title.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div id="subContents">
                        <div>
                            <img src="@/assets/images/Service/ChoiceRental_Sub_06_05.png" />
                        </div>
                        
                        <table class="Noticelist">
                            <colgroup>
                                <col width="80" />
                                <col width="450" />
                                <col width="180" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>번호</th>
                                <th>제목</th>
                                <th>날짜</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="notice in notices" :key="notice.id">
                                    <td>{{ notice.id }}</td>
                                    <!-- <td class="txl">{{ notice.title }}</td> -->
                                    
                                    <td class="txl">
                                        <router-link :to="{ name: 'SupportCenterNoticeView', params: { id: notice.id } }">
                                        {{ notice.title }}
                                        </router-link>
                                    </td>

                                    <td>{{ new Date(notice.createdDate).toLocaleString() }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td class="txc" style="padding:50px 0 80px;" colspan="3">
                                        <a 
                                            v-for="page in totalPages" 
                                            :key="page" 
                                            :class="{ 'Pagingselected': currentPage === page }" 
                                            @click.prevent="fetchNotices(page)"
                                        >{{ page }}</a>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                    
                    <LeftMenu :menuType="'supportCenter'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LeftMenu from '@/components/LeftMenu.vue';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
    name: 'SupportCenterNotice',
    components: {
        LeftMenu
    },
    data() {
        return {
            currentPage: 1,
            totalItems: 0,
            itemsPerPage: 12,
            totalPages: 0,
            notices: []
        };
    },
    methods: {
        async fetchNotices(page = this.currentPage) {
            this.currentPage = page;

            const params = {
                page: this.currentPage,
                pageSize: this.itemsPerPage
            };

            try {
                const response = await axios.get(apiUrl+"/api/Customer/V1/Notices/list", { params });
                
                this.notices = response.data.items;
                this.totalItems = response.data.totalItems;
                this.totalPages = response.data.totalPages;
                this.currentPage = response.data.page;

            } catch (error) {
                console.error('Failed to fetch notices:', error);
            }
        }
    },
    mounted() {
        this.fetchNotices();
    }
}
</script>
<style scoped>
    .txl {
        text-align: left;
    }
</style>