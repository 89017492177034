<template>
    <div class="galleryMain">
        <ul class="item" v-for="portfolio in items" :key="portfolio.id">
            <li class="galleryimg">
                <router-link to="/PortFolio/PortFolioList">
                    <img :src="portfolio.url" :alt="portfolio.name" width="100%" height="210px"/>
                </router-link>
            </li>
            <li>
                <span style="font-size:13px;font-weight:bold;display:block;margin-bottom:20px;">
                    {{ portfolio.name }}
                </span>
                <span style="font-size:11px;">{{ portfolio.descript }}</span>
            </li>
        </ul>
        
    </div>
</template>

<script>

    import axios from 'axios';
    const apiUrl = process.env.VUE_APP_API_URL;
    
    export default {
        data() {
            return {
                items: [],
                currentPage: 1,
                totalItems: 0,
                itemsPerPage: 3,
                selectedSubId: null,
                totalPages: 0, 
            };
        },
        methods: {
            async fetchItems(page = this.currentPage) {
                    
                    this.currentPage = page;
                    try {
                        const params = {
                            page: this.currentPage,
                            pageSize: this.itemsPerPage
                        };
                        if (this.selectedCategory) {
                            params.categoryId = this.selectedCategory;
                        }
                        if (this.selectedSubId) {
                            params.subCategoryId = this.selectedSubId;
                        }
                        const response = await axios.get(apiUrl+"/api/Customer/V1/Portfolio/list", { params });
                        this.items = response.data.items;
                        this.totalItems = response.data.TotalItems;
                        this.totalPages = response.data.totalPages;  
                        this.currentPage = response.data.page;
                        // console.log(this.items);
                    } catch (error) {
                        console.error('Failed to fetch items:', error);
                    }
                }
        },
        mounted() {
            this.fetchItems();
        },
    };
</script>

<style scoped>
.galleryMain .galleryimg img {
    width: 100%;
    height: 210px;
    object-fit: cover; 
}
</style>
