<template>
  <div>
    <div id="Contents">
      <TopArea>
        <TopMenu />
      </TopArea>
      <!-- <MainContents /> -->
      <!-- <TabInfo /> -->
      <!-- <ImageGallery /> -->
      <!-- <BusinessPartner /> -->
      <router-view />
      <!-- <router-view :key="$route.fullPath"/> -->
    </div>
    <BottomArea />
  </div>
</template>

<script>
import TopArea from './components/TopArea.vue'
import TopMenu from './components/TopMenu.vue'
import BottomArea from './components/BottomArea.vue'


// import MainContents from '@/views/Main/MainContents.vue'
// import TabInfo from '@/views/Main/TabInfo.vue'
// import ImageGallery from '@/views/Main/ImageGallery.vue'
// import BusinessPartner from '@/views/Main/BusinessPartner.vue'


export default {
  name: 'App',
  components: {
    TopArea,
    TopMenu,
    // MainContents,
    // TabInfo,
    // ImageGallery,
    // BusinessPartner,
    BottomArea
  }
}
</script>
