<template>
    <div class="menuLine">
        <ul style="float:left">
            <li>
                <router-link to="/">
                    <img alt="Logo" src="@/assets/images/Main/Logo.png" />
                </router-link>
            </li>
        </ul>
        
        <ul id="Menu" style="float:right">
            <li class="TopMenu">
                <router-link to="/Company/CompanyInfo">
                    <!-- <img alt="회사 소개" src="@/assets/images/TopMenu/Menu_01.png" /> -->
                    <img alt="회사 소개" 
                    :src="getMenuImage('/Company').path"
                    :act="getMenuImage('/Company').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/Company').isActive ? 'TopMenuOver' : ''" 
                    />
                </router-link>
            </li>
            <li class="TopMenuBar">
                <img src="@/assets/images/TopMenu/MenuSplitbar.png" alt="bar" />
            </li>
            <li class="TopMenu">
                <router-link to="/Product/ProductList">
                    <!-- <img alt="렌탈사업부" src="@/assets/images/TopMenu/Menu_02.png" /> -->
                    <img alt="렌탈사업부" :src="getMenuImage('/Product').path" 
                    :act="getMenuImage('/Product').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/Product').isActive ? 'TopMenuOver' : ''" />
                </router-link>
            </li>
            <li class="TopMenuBar">
                <img src="@/assets/images/TopMenu/MenuSplitbar.png" alt="bar" />
            </li>
            <li class="TopMenu">
                <router-link to="/Operation/OperationNetwork">
                    <!-- <img alt="SI사업부" src="@/assets/images/TopMenu/Menu_03.png" /> -->
                    <img alt="SI사업부" :src="getMenuImage('/Operation').path" 
                    :act="getMenuImage('/Operation').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/Operation').isActive ? 'TopMenuOver' : ''" />
                </router-link>
            </li>
            <li class="TopMenuBar">
                <img src="@/assets/images/TopMenu/MenuSplitbar.png" alt="bar" />
            </li>
            <li class="TopMenu">
                <router-link to="/Media/MediaAv">
                    <img alt="영상사업부" :src="getMenuImage('/Media').path" 
                    :act="getMenuImage('/Media').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/Media').isActive ? 'TopMenuOver' : ''" />
                    <!-- <img alt="영상사업부" src="@/assets/images/TopMenu/Menu_04.png" /> -->
                </router-link>
            </li>
            <li class="TopMenuBar">
                <img src="@/assets/images/TopMenu/MenuSplitbar.png" alt="bar" />
            </li>
            <li class="TopMenu">
                <router-link to="/PortFolio/PortFolioList">
                    <!-- <img alt="포트폴리오" src="@/assets/images/TopMenu/Menu_05.png" /> -->
                    <img alt="포트폴리오" :src="getMenuImage('/PortFolio').path" 
                    :act="getMenuImage('/PortFolio').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/PortFolio').isActive ? 'TopMenuOver' : ''" />
                </router-link>
            </li>
            <li class="TopMenuBar">
                <img src="@/assets/images/TopMenu/MenuSplitbar.png" alt="bar" />
            </li>
            <li class="TopMenu">
                <router-link to="/SupportCenter/SupportCenterNotice">
                    <!-- <img alt="고객센터" src="@/assets/images/TopMenu/Menu_06.png" /> -->
                    <img alt="고객센터" :src="getMenuImage('/SupportCenter').path" 
                    :act="getMenuImage('/SupportCenter').isActive ? 'on' : 'off'"
                    :class="getMenuImage('/SupportCenter').isActive ? 'TopMenuOver' : ''" />
                </router-link>

            </li>
        </ul>
        
    </div>
</template>

<script>
    import { useRoute } from 'vue-router';

    export default {
        setup() {
            const route = useRoute();

            const getMenuImage = (menuPath) => {
                let imagePath = "Menu_";
                let isActive = route.path.startsWith(menuPath);

                switch(menuPath) {
                    case '/Company':
                        imagePath += "01";
                        break;
                    case '/Product':
                        imagePath += "02";
                        break;
                    case '/Operation':
                        imagePath += "03";
                        break;
                    case '/Media':
                        imagePath += "04";
                        break;
                    case '/PortFolio':
                        imagePath += "05";
                        break;
                    case '/SupportCenter':
                        imagePath += "06";
                        break;
                }

                imagePath += isActive ? "_on.png" : ".png";

                return {
                    path: require(`@/assets/images/TopMenu/${imagePath}`),
                    isActive
                };
            }

            return {
                getMenuImage
            }
        }
    }
</script>