import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; 

//기본 메인페이지 
import IndexView from '@/views/index.vue';

// 회사 소개 
import CompanyInfo from '@/views/Company/CompanyInfo.vue';
import CompanyMap from '@/views/Company/CompanyMap.vue';
import CompanyOrgani from '@/views/Company/CompanyOrgani.vue';
import CompanyPerform from '@/views/Company/CompanyPerform.vue';

// 렌탈 사업부
import ProductList from '@/views/Product/ProductList.vue';

// SI 사업부 
import OperationNetwork from '@/views/Operation/OperationNetwork.vue';
import OperationSIBiz from '@/views/Operation/OperationSIBiz.vue';
import OperationWebCasting from '@/views/Operation/OperationWebCasting.vue';

// 영상 사업부
import MediaAv from '@/views/Media/MediaAv.vue';
import MediaBeam from '@/views/Media/MediaBeam.vue';
import MediaLed from '@/views/Media/MediaLed.vue';

// 포트 폴리오
import PortFolioList from '@/views/PortFolio/PortFolioList.vue';

// 고객 센터
import SupportCenterNotice from '@/views/SupportCenter/SupportCenterNotice.vue';
import SupportCenterNoticeView from '@/views/SupportCenter/SupportCenterNoticeView.vue';


const routes = [
    { path: '/', name: 'Index', component: IndexView },

    { 
        path: '/Company/CompanyInfo', 
        name: 'CompanyInfo', 
        component: CompanyInfo, 
        beforeEnter: (to, from, next) => {
            store.commit('setActiveMenu', 'Company_menu_01'); 
            next();
        }

    },
    { path: '/Company/CompanyPerform', name: 'CompanyPerform', component: CompanyPerform },
    { path: '/Company/CompanyOrgani', name: 'CompanyOrgani', component: CompanyOrgani },
    { path: '/Company/CompanyMap', name: 'CompanyMap', component: CompanyMap },

    { path: '/Product/ProductList', name: 'ProductList', component: ProductList },

    { path: '/Operation/OperationNetwork', name: 'OperationNetwork', component: OperationNetwork },
    { path: '/Operation/OperationWebCasting', name: 'OperationWebCasting', component: OperationWebCasting },
    { path: '/Operation/OperationSIBiz', name: 'OperationSIBiz', component: OperationSIBiz },

    { path: '/Media/MediaAv', name: 'MediaAv', component: MediaAv },
    { path: '/Media/MediaBeam', name: 'MediaBeam', component: MediaBeam },
    { path: '/Media/MediaLed', name: 'MediaLed', component: MediaLed },

    { path: '/PortFolio/PortFolioList', name: 'PortFolioList', component: PortFolioList },

    { path: '/SupportCenter/SupportCenterNotice', name: 'SupportCenterNotice', component: SupportCenterNotice },
    { path: '/SupportCenter/support-center-notice-view/:id', name: 'SupportCenterNoticeView', component: SupportCenterNoticeView },
    // {
    //     path: '/support-center-notice-view/:id',
    //     name: 'SupportCenterNoticeView',
    //     component: () => import('@/views/SupportCenterNoticeView.vue'),
    // },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
