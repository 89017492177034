<template>
    <div id="TopArea">
        <div class="topLine">
            <ul style="width:1000px;margin:0 auto;">
                <li style="float:right"><img src="@/assets/images/Main/TopContactmail.png" /></li>
            </ul>
        </div>
    <TopMenu/>
    </div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'

export default {
    name: 'TopArea',
    components: {
        TopMenu,
    }
}

</script>

<style lang="scss" scoped>

</style>