<template>
    <div id="MainContents">
        <ul>
            <li><img src="@/assets/images/Main/Main_01.png" /></li>
        </ul>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>