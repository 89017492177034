<template>
    <div>
        <div id="Contents">
            <div id="SubMainContents">
                <div id="SubTitle">
                    <ul>
                        <li><img src="@/assets/images/TitleBar/Portfolio.png" /></li>
                    </ul>
                </div>
                <div id="subArea">
                    <div>
                        <img src="@/assets/images/portfolio/ChoiceRental_Sub_09_05.png" />
                        <div id="ItemList">
                            <div class="item" v-for="portfolio in items" :key="portfolio.id">
                                <div class="portfolioImg">
                                    <img :src="portfolio.url" 
                                    :alt="portfolio.name" 
                                    @click="openModal(portfolio)"/>
                                </div>
                                <h4>{{ portfolio.name }}</h4>
                            </div>
                        </div>
                        
                        <div class="pagination" style="padding:30px 0;">
                            <a @click.prevent="fetchItems(currentPage - 1)" v-if="currentPage > 1">이전</a>
                            <a 
                                v-for="page in displayedPages" 
                                :key="page" 
                                :class="{ 'Pagingselected': currentPage === page }" 
                                @click.prevent="fetchItems(page)"
                            >{{ page === '...' ? page : `${page}` }}</a>
                        
                            <a @click.prevent="fetchItems(currentPage + 1)" v-if="currentPage < totalPages">다음</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- 모달 이미지 뷰 -->
        <div v-if="showModal" class="modal">
            <div class="modal-header">
                <span class="modal-title">{{ modalImageTitle }}</span>
                <button class="modal-close" @click="closeModal">X</button>
            </div>
            <div class="modal-content">
                <!-- <span class="close-button" @click="closeModal">×</span> -->
                <img :src="modalImageSrc" :alt="modalImageAlt">
                <!-- <h3>{{ modalImageTitle }}</h3>
                <p>{{ modalImageDescription }}</p> -->
                <div class="description-box">
                    <h3>{{ modalImageTitle }}</h3>
                    <p>{{ modalImageDescription }}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import axios from 'axios';
    const apiUrl = process.env.VUE_APP_API_URL;

    export default {
        data() {
            return {
                items: [],
                currentPage: 1,
                totalItems: 0,
                itemsPerPage: 12,
                selectedSubId: null,
                totalPages: 0, 
                showModal: false,
                modalImageSrc: '',
                modalImageAlt: '',
                modalImageTitle: '',
                modalImageDescription: '',
            };
        },
        methods: {
            
            openModal(portfolio) {
                    this.modalImageSrc = portfolio.url;
                    this.modalImageAlt = portfolio.name;
                    this.modalImageTitle = portfolio.name;
                    this.modalImageDescription = portfolio.descript;
                    this.showModal = true;
            },
            openImage(imageSrc) {
                this.modalImage = imageSrc;
                this.showModal = true;
            },
            closeModal() {
                this.showModal = false;
            },
            selectCategory(category) {
                this.selectedCategory = String(category);
                this.selectedSubId = null;
                this.fetchItems();
            },
            handleSubItemClicked(subId) {
                this.selectedSubId = subId;
                this.fetchItems();
            },
            async fetchItems(page = this.currentPage) {
                    
                    this.currentPage = page;
                    try {
                        const params = {
                            page: this.currentPage,
                            pageSize: this.itemsPerPage
                        };
                        if (this.selectedCategory) {
                            params.categoryId = this.selectedCategory;
                        }
                        if (this.selectedSubId) {
                            params.subCategoryId = this.selectedSubId;
                        }
                        const response = await axios.get(apiUrl+"/api/Customer/V1/Portfolio/list", { params });
                        this.items = response.data.items;
                        this.totalItems = response.data.TotalItems;
                        this.totalPages = response.data.totalPages;  
                        this.currentPage = response.data.page;
                        // console.log(this.items);
                    } catch (error) {
                        console.error('Failed to fetch items:', error);
                    }
                }
        },
        mounted() {
            this.fetchItems();
        },
        computed: {
            displayedPages() {
                const sidePages = 2;
                let pages = [];
                for(let i = 1; i <= this.totalPages; i++) {
                    if (i === 1 || i === this.totalPages || (i >= this.currentPage - sidePages && i <= this.currentPage + sidePages)) {
                        pages.push(i);
                    }
                }
                const withDots = [];
                for (let i = 0; i < pages.length; i++) {
                    if (i < pages.length - 1 && pages[i + 1] - pages[i] > 1) {
                        withDots.push(pages[i], '...');
                    } else {
                        withDots.push(pages[i]);
                    }
                }
                return withDots;
            }
        }
    };

</script>

<style>
    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%;
        background-color: #fff;
        border: 1px solid #ccc;
        z-index: 1000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;
        background-color: #f5f5f5;
    }
    
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }

    .modal-content {
        padding: 20px;
    }

    .modal-close {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }

    .modal-content img {
        max-width: 100%;
        max-height: 70vh;
        display: block;
        margin: 0 auto;
    }

    .description-box {
        border: 1px solid #ccc;
        padding: 10px; 
        margin-top: 20px;
        overflow-y: auto;
        max-height: 200px;
        background-color: #f5f5f5;
        border-radius: 5px;
    }
    
    .description-box h3 {
        margin-bottom: 10px;
    }
    
    .description-box p {
        white-space: pre-line;
    }
    
    .pagination a {
        padding: 5px 10px;
        margin: 0 2px;
        text-decoration: none;
        border: 1px solid #ccc;
        border-radius: 3px;
        color: #333;
        transition: background-color 0.2s;
    }
    
    .pagination a:hover {
        background-color: #f5f5f5;
    }
    
    .pagination .Pagingselected {
        font-weight: bold;
        background-color: #007bff;
        color: #fff;
    }

    #subArea {
        background: none !important;
    }


    /*
    #ItemList .item {
    }
    */

    #ItemList .item:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .portfolioImg {
        margin-bottom: 15px; 
    }

    .portfolioImg img {
        border-radius: 5px;
        transition: transform 0.3s; 
    }

    .portfolioImg img:hover {
        transform: scale(1.05); 
    }

    h4 {
        font-size: 18px; 
        font-weight: 600; 
        text-align: center;
    }


    
</style>