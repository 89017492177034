<template>
    <div>
        <!-- 서브 카테고리 목록 -->
        <ul id="SubCategory">
            <li v-for="subCategory in subCategories" :key="subCategory.id">
                <a @click.prevent="handleItemClick(subCategory.id)" class="sub-category-link">{{ subCategory.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
export default {
    data() {
        return {
        // categories: [],
        subCategories: []
        };
    },
    mounted() {
        // this.loadCategories();
        // this.loadSubCategories(1);
        if (this.$route.query.search) {
            this.loadAllSubCategories();
        } else {
            this.loadSubCategories(this.selectedCategory);
        }
    },
    methods: {
        loadAllSubCategories() {
            const endpoint = apiUrl+"/api/Customer/V1/Products/subcategories";
            axios.get(endpoint)
                .then(response => {
                    this.subCategories = response.data;
                })
                .catch(error => {
                console.error("Error fetching all subcategories:", error);
                });
        },

        loadSubCategories(categoryId = null) {
            let endpoint = apiUrl+"/api/Customer/V1/Products/subcategories";
            if (categoryId) {
                endpoint += `?categoryId=${categoryId}`;
            }
            
            axios.get(endpoint)
                .then(response => {
                    this.subCategories = response.data;
            })
            .catch(error => {
                console.error("Error fetching subcategories:", error);
            });
        },
        handleItemClick(subId) {
            this.$emit('item-clicked', subId);
        },
        selectCategory(categoryId) {
            this.currentCategoryId = categoryId;
        }
    },
    props: {
        selectedCategory: {
            type: String,
            default: null
        }
    },
    watch: {
        // selectedCategory(newVal) {
        //     // console.log(newVal);
        //     if (newVal) {
        //         this.loadSubCategories(newVal);
        //     }
        // }

        selectedCategory(newVal) {
            if (this.$route.query.search || !newVal) {
                this.loadAllSubCategories();
            } else {
                this.loadSubCategories(newVal);
            }
        }

    },
}
</script>

<style scoped>
    .category-link,
    .sub-category-link {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
    }
</style>